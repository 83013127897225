<template>
    <div class="config-property">
        <div>
            {{ label }}
        </div>
        <label class="input-radio" v-for="(tipo, idx) in tipos" :key="idx">
            <div class="row">
                <div class="col-sm-1">
                    <input type="radio" :value="tipo.value" :name="label" v-model="value[config]">
                </div>
                <div class="col-sm-5">
                    {{ tipo.label }}
                </div>
            </div>
        </label>
    </div>
</template>

<script>
  export default {
    name: "BOOLEAN_COMPONENT",
    props: [
      'value',
      'config',
      'label',
    ],
    data() {
      return {
        tipos: [
          {
            label: 'Sim',
            value: true,
          },
          {
            label: 'Não',
            value: false,
          },
        ]
      }
    }
  }
</script>